<template>
  <div class="not-found">
    <div class="title">Page Not Found</div>
    <div class="back">
      <span @click="goBack" role="button" tabindex="0"> Click here </span>
      to go back to app
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import router from '../router';
import store from '../store';

export default {
  setup() {
    const goBack = () => {
      store.dispatch('setNotFound', false);
      router.push('/');
    };

    return {
      goBack,
    };
  },
};
</script>

<style lang="scss" scoped>
.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  width: 100%;
  height: 100%;
}
.title {
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.4px;
}
.back {
  margin: 10px;

  :first-child {
    color: $hty-orange;
    cursor: pointer;
  }
}
</style>
